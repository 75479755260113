header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 5;

    .headerTop {
        height: 30px;
        background-color: @black;
        width: 100%;
    }

    .navbar-brand {
        position: absolute;
        left: 50%;
        .transform(translateX(-50%));
        top: 70px;
        .transition(all ease-in-out .3s);

        img {
            .transition(all ease-in-out .3s);
        }

        &-affix {
            .transition(all ease-in-out .3s);
            .opacity(0);
            width: 0;
            height: 0;
            overflow: hidden;

            img {
                margin-top: 7px;
            }

            @media all and (max-width: 1023px) {
                .tablet &,
                .mobile & {
                    .opacity(1);
                    display: block;
                    height: auto;
                    width: auto;
                    .transition(all ease-in-out .3s);
                    position: absolute;
                    left: 15px;
                    top: 0;

                    &.visible-sm {
                        display: block!important;
                    }
                }
            }
        }
    }

    .navbar-default {
        // height: 90px;
        background-color: fade(@black, 70%);
        .transition(all ease-in-out .3s);

        .container-full {
            .navbar-collapse {
                .transition(all ease-in-out .3s);
                
                @media all and (min-width: 1024px) {
                    .flexbox(row);
                    justify-content: center;
                }

                .navbar-nav {
                    font-size: 0.75rem;

                    > li {
                        > a {
                            padding: 13px 25px;
                            .transition(all ease-in-out .3s);
                            text-transform: uppercase;
                            color: @gold;
                            letter-spacing: .3em;

                            &:hover,
                            &:focus {
                                color: @white;
                            }
                        }

                        &.active {
                            > a {
                                // background-color: @black;
                                color: @white;
                            }
                        }

                        &.dropdown {
                            > a {
                                position: relative;

                                &:before {
                                    .transition(all ease-in-out .3s);
                                    content: "";
                                    position: absolute;
                                    top: 50%;
                                    .transform(translateY(-50%));
                                    right: 10px;
                                    width: 0;
                                    height: 0;
                                    border-left: 5px solid transparent;
                                    border-right: 5px solid transparent;
                                    border-top: 8px solid @gold;
                                }

                                &:hover,
                                &:focus {
                                    &:before {
                                        border-top-color: @white;
                                    }
                                }

                                /*img menu*/
                                // &+.dropdown-menu:after {
                                //     content: "";
                                //     position: absolute;
                                //     right: -300px;
                                //     top: 0;
                                //     width: 300px;
                                //     height: 100%;
                                //     background-size: cover;
                                //     background-position: center center
                                // }
                                //
                                // &.menu-camere+.dropdown-menu:after {
                                //     background-image: url("/images/menu/camere.jpg");
                                // }
                                // &.menu-smart+.dropdown-menu:after {
                                //     background-image: url("/images/menu/smart.jpg");
                                // }
                                // &.menu-tuffo+.dropdown-menu:after {
                                //     background-image: url("/images/menu/tuffo.jpg");
                                // }
                                // &.menu-food+.dropdown-menu:after {
                                //     background-image: url("/images/menu/food.jpg");
                                // }
                                // &.menu-dove+.dropdown-menu:after {
                                //     background-image: url("/images/menu/dove.jpg");
                                // }
                                // &.menu-eventi+.dropdown-menu:after {
                                //     background-image: url("/images/menu/eventi.jpg");
                                // }
                            }

                            &.active {
                                a:before {
                                    border-top-color: @white;
                                }
                            }

                            .dropdown-menu {
                                background-color: fade(@black, 60%);
                                padding: 0;
                                margin-top: 2px;

                                >li {
                                    position: relative;

                                    &.active {
                                        a {
                                            background-color: fade(@gold, 60%);
                                        }
                                    }

                                    >a {
                                        padding: 12px 30px;
                                        text-transform: uppercase;
                                        font-size: .8em;
                                        .transition(all ease-in-out .3s);

                                        &:hover {
                                            background-color: fade(@gold, 60%);
                                        }
                                    }

                                    &:last-child {
                                        &:after {
                                            content: none;
                                        }
                                    }

                                    &:after {
                                        content: "";
                                        position: absolute;
                                        background-color: fade(@black, 15%);
                                        height: 1px;
                                        width: 100%;
                                        left: 0;
                                        bottom: 0;
                                    }
                                }
                            }
                        }

                        &.open {
                            a {
                                color: @white;
                            }

                            .dropdown-menu {
                                // .flexbox(column);
                                // justify-content: center;
                            }
                        }
                    }
                }
            }
        }
    }
}

header {
    &.affix,
    .no-background &  {
        .navbar-brand {
            .opacity(0);
            top:-20px;
            pointer-events: none;
        }

        .navbar-default .container-full .navbar-collapse {
            float: right;
            padding: 0;
            width: 100%;
            justify-content: flex-end;


            .navbar-brand-affix {
                .opacity(1);
                display: block;
                height: auto;
                width: auto;
                .transition(all ease-in-out .3s);
                position: absolute;
                left: 15px;
            }
        }
    }
}

/*menu lingua header top*/
.langmenu {
    position: absolute;
    right: 20px;
    top: 0;

    @media all and (max-width: 767px) {
        right: 0;
    }

    .lang-inline {
        li {
            a {
                color: @white;
                letter-spacing: .3em;
                font-size: .7em;
                text-decoration: none;
                .transition(all ease-in-out .3s);

                &:hover {
                    color: @gold;
                }
            }

            &.lang-active {
                a {
                    color: @gold;
                }
            }
        }
    }

    // .btn {
    //     background-color: transparent;
    //     color: #fff;
    //     text-transform: uppercase;
    //     border: none;
    //     background: none;
    //     outline: none;
    //     height: 30px;
    //     line-height: 30px;
    //
    //     @media all and (max-width: 767px) {
    //         padding: 0 10px;
    //     }
    //
    //     span {
    //         margin-left: 10px;
    //         line-height: 32px;
    //
    //         .mobile &,
    //         .tablet & {
    //             font-size: .8em;
    //
    //             &:before {
    //                 vertical-align: sub;
    //             }
    //         }
    //
    //         &:before {
    //             font-size: 1.6em;
    //             vertical-align: middle;
    //         }
    //     }
    // }
    //
    // ul {
    //     margin-left: -8px!important;
    //     border-top: none;
    //     .border-radius(0 0 3px 3px);
    //     min-width: auto;
    //     background-color: #222;
    //
    //     li {
    //         border-bottom: 1px solid #000;
    //         margin-left: 0!important;
    //         margin-right: 0!important;
    //
    //         &:last-child {
    //             border: none;
    //         }
    //
    //         a {
    //             text-transform: uppercase;
    //             color: #fff;
    //             font-weight: bold;
    //
    //             &:hover {
    //                 color: #fff;
    //                 background-color: #666;
    //             }
    //
    //             img {
    //                 border: 1px solid #ecebeb;
    //             }
    //         }
    //     }
    // }
}

/*H1*/
.col-sm-12.h1 {
    margin: 0;
    padding: 0;
    float: left;
}

.h1 h1 {
    font-size: 0.7rem;
    z-index: 6;
    color: fade(@white, 60%);
    left: 0;
    text-align: left;
    top: 0;
    margin: 8px;
    padding: 0;

    @media all and (max-width: 768px){
        width: 80%;
        overflow: hidden;
        line-height: 12px;
        height: 12px;
        font-size: .6rem;
    }
}

/*-------------background-----------------------------------------------------*/
.background {
    height: 100vh;
    overflow: hidden;
}
.swiper-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 0!important;

    .swiper-wrapper {
        height: 100%;
    }

    &-background {
        height: 100vh;

        &.qnt {
            .swiper-slide .claim {
                top: 50%;
                .opacity(1);
            }
        }

        @media all and (max-width: 1024px) {
            height: 65vh!important;
        }

        .swiper-slide {
            >span {
                background-color: fade(@black, 40%);
                background-blend-mode: multiply;
            }

            .claim {
                text-align: center;
                position: absolute;
                top: 75%;
                left: 50%;
                .transform(translate(-50%, -50%));
                .opacity(0);
                .transition(all ease-in-out .15s);

                h2 {
                    font-size: 4.6vw;
                    color: @white;
                    font-family: @serif;
                    margin-bottom: 50px;
                }

                a {
                    color: @white;
                }
            }
        }

        .tablet & {
            height: 95vh!important;
        }

        &-custom {
            height: 80vh!important;
            background-color: #000;

            .tablet & {
                height: 70vh!important;
            }

            .mobile & {
                height: 65vh!important;
            }
        }

        .swiper-button {
        width: 100%;

        .swiper-button-next,
        .swiper-button-prev {
            color: @gold;
            z-index: 5;
            font-family: "icomoon";
            position: absolute;
            top: 50%;
            width: 50px;
            height: 50px;
            background-color: fade(@black, 50%);

            .mobile & {
                width: 30px;
                height: 30px;
            }
        }

        .swiper-button-next {
            right: 0;

            &:before {
                content: "\e907";
                line-height: 50px;
                font-size: 25px;
                position: absolute;
                left: 50%;
                margin-left: -12px;

                .mobile & {
                    line-height: 30px;
                }
            }
        }
        .swiper-button-prev {
            left: 0;

            &:before {
                content: "\e906";
                line-height: 50px;
                font-size: 25px;
                position: absolute;
                right: 50%;
                margin-right: -12px;

                .mobile & {
                    line-height: 30px;
                }
            }
        }
    }

    }

}

.incentivi {
    width: 100%;
    position: absolute;
    z-index: 0;
    bottom: 0;
    padding: 3px 0;
    p {
        margin: 0;
        text-align: center;
        font-family: @serif;

        strong {
            color: @gold;
        }
    }
}

@media all and (min-width: 992px) and (max-width: 1023px) {
    #nav-toggle.visible-sm {
        display: block!important;
    }
}