/*footer*/
footer {
    padding-bottom: 50px;
    position: relative;
    background-color: @black;
    font-family: @serif;

    >.container-full {
        border-bottom: 1px solid @gold;
        margin-bottom: 30px;
    }

    .link-footer {
        padding: 30px 0;
        .flexbox(row);
        justify-content: space-between;
        font-family: @sans-serif;

        @media all and (max-width: 1280px) {
            width: 100%;
        }

        >p {
            flex-grow: 1;

            a {
                color: @gold;
                letter-spacing: .3em;
                text-transform: uppercase;
                font-size: .75em;
                text-decoration: none;
                .transition(all ease-in-out .3s);

                &:hover {
                    color: lighten(@gold, 15%);
                }
            }
        }
    }

    .container {
        align-items: center;

        .dati-hotel {
            color: @white;
            font-size: .8em;

            >p a {
                margin-right: 10px;
                text-decoration: none;

                span {
                    color: @white;
                    font-size: 1.6em;
                }
            }
        }

        .logo-footer {
            img {
                margin: 0 auto;
            }
        }

        /*credits*/
        .credits {
            color: @white;

            p {
                margin: 0;
                padding: 5px 0;
                font-size: .8em;
            }

            a {
                color: @gold;
                outline: none;
            }
        }
    }
}

#dashboard {
    position: fixed;
    z-index: 10;
    left: 0;
    bottom: 0;
    height: 50px;
    line-height: 50px;
    width: 100%;
    border-top: 1px solid fade(@black, 70%);
    box-shadow: 0 -2px 12px rgba(0,0,0,0.45);
    background-color: @black;

    .tablet & {
        height: 60px;
        line-height: 60px;
    }

        & > a {
            float: left;
            width: 33.333%;
            font-size: .6em;
            color: #fff;
            text-align: center;
            .border-radius(0);
            height: 100%;
            line-height: 1.5;
            padding-top: 6px;
            border-right: 1px solid fade(#fff, 10%);

            .tablet & {
                font-size: .8em;
            }

            &:after {
                content: none;
            }

            &:last-child {
                border-right: none;
            }

            &:before {
                font-size: 1.7em;
                display: block;
                margin-right: 0;
            }
        }
}
