/*Block full*/
.block-full {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: @black;

    >span {
        background-color: fade(@black, 40%);
        background-blend-mode: multiply;
    }

    .container,
    .container-full {
        height: 450px;
        overflow: hidden;

        .item {
            position: relative;
            z-index: 0;
            text-align: center;
            color: @white;
            .transform(translateY(-50%));
            .transition(all ease-in-out .3s);
            .opacity(0);
            top: 60%;

            &.qnt {
                .opacity(1);
                top: 50%
            }

            >h3 {
                text-transform: uppercase;
                font-size: 1vw;
                letter-spacing: .3em;

                @media all and (max-width: 1369px) {
                    font-size: 1.2vw;
                }
            }

            >h2 {
                font-family: @serif;
                font-size: 3.2vw;
                margin-bottom: 60px;
            }

            a {
                color: @white;
            }
        }
    }
    .container {
        .item {
            text-align: left;

            >h2 {
                margin-bottom: 15px;
                padding-bottom: 15px;
                border-bottom: 1px solid fade(@white, 80%);
            }
        }
    }
}

.article-home {
    margin-top: 50px;
    margin-bottom: 80px;

    >h2,
    >h3 {
        text-align: center;
    }
    >h2 {
        color: @black;
        font-family: @serif;
        font-size: 2.6vw;
    }
    >h3 {
        color: @gold;
        margin-bottom: 30px;
        text-transform: uppercase;
        font-size: .8vw;
        letter-spacing: .3em;
    }

    .item {
        width: 49.5%;
        height: 40vw;
        float: left;
        background-color: @black;
        position: relative;
        &:nth-child(1) {
            margin-right: 1%;
        }

        &:nth-child(2) {
            margin-bottom: .8vw;
        }

        &:nth-child(2),
        &:nth-child(3) {
            width: 49.5%;
            height: 19.6vw;
        }

        >span {
            background-color: fade(@black, 40%);
            background-blend-mode: multiply;
        }

        .text {
            display: inline-block;
            width: 80%;
            position: relative;
            z-index: 0;
            color: @white;
            top: 50%;
            left: 50%;
            .transform(translate(-50%, -50%));
            .opacity(0);
            .transition(all ease-in-out .3s);

            &.qnt {
                .opacity(1);
            }

            >h3 {
                text-transform: uppercase;
                font-size: .8em;
                letter-spacing: .3em;
            }
            >h2 {
                font-size: 2.4vw;
                font-family: @serif;
                margin-top: 2vw;
                margin-bottom: 2vw;
            }

            >blockquote {
                font-size: 2.4vw;
                font-family: @serif;
                margin-top: 1vw;
                margin-left: 0;
                margin-bottom: 0;
            }
            small {
                text-align: right;
                float: right;
            }

            a {
                color: @white;
            }
        }
    }
}

.titleModules {
    position: relative;
    .opacity(0);
    .transition(all ease-in-out .3s);
    top: 0;

    &.qnt {
        .opacity(1);
    }

    >h2,
    >h3 {
        text-align: center;
    }
    >h2 {
        color: @black;
        font-family: @serif;
        font-size: 2.6vw;
    }
    >h3 {
        color: @gold;
        margin-bottom: 30px;
        text-transform: uppercase;
        font-size: 1vw;
        letter-spacing: .3em;
    }
}

.list-dintorni-home {

    .k2ItemsBlock {
        .container-full {
            ul {
                padding: 0;

                li {
                    background-color: @black;
                    overflow: hidden;
                    width: 49.5%;
                    height: 25vw;
                    float: left;
                    margin-bottom: .8vw;
                    cursor: pointer;
                    position: relative;
                    .opacity(0);
                    top: 30px;
                    .transition(all ease-in-out .3s .15s);

                    &.qnt {
                        .opacity(1);
                        top: 0px;
                    }

                    >span {
                        background-color: fade(@black, 60%);
                        background-blend-mode: multiply;
                        .transition(all ease-in-out .3s);
                    }

                    &:nth-child(2n+1) {
                        margin-right: 1%;
                    }

                    .text {

                        color: @white;
                        width: 70%;
                        left: 50%;
                        top: 50%;
                        .transform(translate(-50%, -50%));
                        .transition(all ease-in-out .3s);

                        >h2 {
                            font-size: 3vw;
                            font-family: @serif;
                        }
                        >h3 {
                            font-size: 1.8vw;
                            font-family: @serif;
                        }

                        a.btn {
                            margin-top: 30px;
                            color: @white;
                            position: relative;

                            &:after {
                                z-index: -1;
                                content: "";
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                height: 0%;
                                width: 100%;
                                background-color: @gold;
                                // .transition(all ease-in-out .3s);
                            }

                            &:hover {
                                color: @white;
                                background-color: transparent;
                            }
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            left: -50px;
                            bottom: 130%;
                            height: 1000%;
                            width: 1px;
                            background-color: lighten(@gold, 10%);
                            .transition(all ease-in-out .5s .2s);
                        }
                        &:after {
                            content: "";
                            position: absolute;
                            left: -50px;
                            .transform(translateX(-50%));
                            bottom: 130%;
                            margin-bottom: -14px;
                            height: 14px;
                            width: 14px;
                            .border-radius(50%);
                            border: 1px solid lighten(@gold, 10%);
                            .transition(all ease-in-out .5s .2s);
                        }
                    }

                    &:hover {
                        >span {
                            background-color: fade(@black, 30%);
                            .transform(scale(1.05));
                        }

                        .text {
                            &:before,
                            &:after {
                                bottom: 50%;
                            }

                            a.btn {
                                &:after {
                                    height: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.list-article {

    &.dintorni {
        .k2ItemsBlock .container-full ul li .text .wrap_text {
            // font-size: .8em;
        }
        .k2ItemsBlock .container-full ul li {
            &:nth-child(5) {
                margin-right: 0;
                width: 100%;
            }

            &:nth-last-child(1),
            &:nth-last-child(3) {
                margin-right: 0;
            }
            &:nth-last-child(2),
            &:nth-last-child(4) {
                margin-right: 1%;
            }
        }
    }

    &.eventi {
        .k2ItemsBlock .container-full ul li .text .wrap_text {
            // font-size: .8em;
        }
        .k2ItemsBlock .container-full ul li {
            &:nth-child(5) {
                margin-right: 0;
                width: 100%;
            }

            &:nth-last-child(1),
            &:nth-last-child(3),
            &:nth-last-child(5) {
                margin-right: 0;
            }
            &:nth-last-child(2),
            &:nth-last-child(4),
            &:nth-last-child(6) {
                margin-right: 1%;
            }
        }
    }

    &.tour {
        .k2ItemsBlock .container-full ul li .text {
            // font-size: .8em;
        }
    }

    .k2ItemsBlock {
        .container-full {
            ul {
                padding: 0;

                li {
                    background-color: @black;
                    overflow: hidden;
                    width: 49.5%;
                    height: 30vw;
                    float: left;
                    margin-bottom: .8vw;
                    .opacity(0);
                    top: 30px;
                    
                    &:nth-child(odd) {
                        .transition(all ease-in-out .3s .2s);
                    }
                    &:nth-child(even) {
                        .transition(all ease-in-out .3s .4s);
                    }

                    &.qnt {
                        .opacity(1);
                        top: 0px;
                    }

                    @media all and (max-width: 1281px) {
                        height: 35vw;
                    }

                    >span {
                        background-color: fade(@black, 30%);
                        background-blend-mode: multiply;
                        .transition(all ease-in-out .3s);
                    }

                    &:nth-child(2n+1) {
                        margin-right: 1%;
                    }

                    .text {
                        color: @white;
                        width: 75%;
                        left: 50%;
                        top: 5%;
                        .transform(translate(-50%, 0%));
                        .transition(all ease-in-out .4s .1s);
                        font-size: 1.05vw;
                        line-height: 1.3vw;

                        a {
                            color: @gold;
                        }

                        >h2 {
                            font-size: 2.8vw;
                            font-family: @serif;
                            position: relative;
                            margin-top: 0;

                            &:before {
                                content: "";
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                height: 1px;
                                width: 100%;
                                background-color: @white;
                                .transition(all ease-in-out .2s);
                            }
                        }

                        >h3 {
                            font-size: 1.8vw;
                            font-family: @serif;
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            left: -50px;
                            bottom: 50%;
                            height: 1000%;
                            width: 1px;
                            background-color: lighten(@gold, 10%);
                        }
                        &:after {
                            content: "";
                            position: absolute;
                            left: -50px;
                            .transform(translateX(-50%));
                            bottom: 50%;
                            margin-bottom: -14px;
                            height: 14px;
                            width: 14px;
                            .border-radius(50%);
                            border: 1px solid lighten(@gold, 10%);
                        }

                        .wrap_text {
                            border: 1px solid @gold;
                            padding: 15px 30px;
                            .opacity(0);
                            .transition(all ease-in-out .4s .1s);

                            p {
                                // line-height: 0;
                                .transition(all ease-in-out .4s .1s);
                            }
                        }
                    }
                    
                    .mobile &,
                    .tablet &,
                    &:hover {
                        >span {
                            background-color: fade(@black, 75%);
                            .transform(scale(1.05));
                        }

                        .text {
                            top: 50%;
                            .transform(translate(-50%, -50%));

                            h2:before {
                                width: 0%;
                            }

                            .wrap_text {
                                .opacity(1);

                                p {
                                    // line-height: 1em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*Think green*/
.block-4 {
    margin-top: 30px;

    >h3,>h2 {
        text-align: center;
        position: relative;
    }

    >h2 {
        color: @black;
        font-size: 2.4em;
        font-family: @serif;
        margin: 0;
    }

    >h3 {
        color: @gold;
        text-transform: uppercase;
        font-size: .75em;
        letter-spacing: .3em;
    }

    .container-full {
        padding-top: 30px;
        padding-bottom: 45px;
    }

    &.social-home {
        position: relative;
        .opacity(0);
        top: 30px;
        .transition(all ease-in-out .3s);

        &.qnt {
            .opacity(1);
            top: 0;
        }

        .background-cover {
            height: 280px;
            border-right: 20px solid @white;

            &:last-child {
                border-right: none;
            }
        }
    }
}

/*offerte home*/
.list-offers {
    margin-top: 50px;
    margin-bottom: 50px;
    z-index: 0;
    position: relative;

    &.offerte-home {
        .container-full {
            padding: 0 50px;
        }

        position: relative;
        .opacity(0);
        .transition(all ease-in-out .3s);
        top: 30px;

        &.qnt {
            .opacity(1);
            top: 0;
        }
    }

    &.offerte-pagina {
        .container-full .swiper-wrapper .swiper-slide {
            position: relative;
            .opacity(0);
            top: 30px;
            .transition(all ease-in-out .3s);

            &.qnt {
                top: 0;
                .opacity(1);
            }
        }
    }

    >h2 {
        z-index: 1;
        position: relative;
        font-family: @sans-serif;
        font-weight: 900;
        text-align: center;
        font-size: 3.6em;
        padding: 40px;
    }

    .container-full {
        .swiper-wrapper {
            .flexbox(row);
            align-content: space-between;

            .swiper-slide {
                width: 31%;
                height: 280px;
                padding: 20px 50px;
                color: @black;
                border: 1px solid @gold;
                text-align: right;
                position: relative;
                font-size: .9em;
                cursor: pointer;
                .transition(all ease-in-out .3s);
                .box-shadow(inset 0 0 0 1px fade(@gold, 0%));

                &:hover {
                    .box-shadow(inset 0 0 0 1px fade(@gold, 100%));


                    >a {
                        margin-right: 20px;
                        border: 1px solid fade(@gold, 30%);

                            &:before {
                                left: -130px;
                                width: 130px;
                            }
                        }
                }

                .background-cover {
                    position: relative;
                    height: 250px;
                }

                h2 {
                    font-family: @serif;
                    color: @gold;
                }

                >a {
                    position: absolute;
                    text-decoration: none;
                    font-weight: bold;
                    bottom: 40px;
                    right: 60px;
                    letter-spacing: .3em;
                    font-size: .7em;
                    display: block;
                    color: @gold;
                    text-transform: uppercase;
                    border: 1px solid fade(@gold, 0%);
                    .transition(all ease-in-out .3s .1s);
                    padding: 7px 10px;

                    &:before {
                        content: "";
                        position: absolute;
                        height: 1px;
                        background-color: @gold;
                        width: 150px;
                        bottom: 50%;
                        left: -150px;
                        .transition(all ease-in-out .3s);
                        .transition(all ease-in-out .3s .1s);
                    }

                    &:hover {
                        margin-right: 20px;
                        border: 1px solid fade(@gold, 30%);

                        &:before {
                            left: -130px;
                            width: 130px;
                        }
                    }
                }
            }
        }

        .swiper-button {
        width: 100%;

        .swiper-button-next,
        .swiper-button-prev {
            color: @gold;
            z-index: 5;
            font-family: "icomoon";
            position: absolute;
            top: 50%;
            width: 50px;
            height: 50px;
            // background-color: fade(@black, 50%);

            .mobile & {
                width: 30px;
                height: 30px;
            }
        }

        .swiper-button-next {
            right: 0;

            &:before {
                content: "\e907";
                line-height: 50px;
                font-size: 25px;
                position: absolute;
                left: 50%;
                margin-left: -12px;

                .mobile & {
                    line-height: 30px;
                }
            }
        }
        .swiper-button-prev {
            left: 0;

            &:before {
                content: "\e906";
                line-height: 50px;
                font-size: 25px;
                position: absolute;
                right: 50%;
                margin-right: -12px;

                .mobile & {
                    line-height: 30px;
                }
            }
        }
    }
    }
}

/*lista-camere*/
.list-rooms {
    ul {
        li {
            height: 30vw;
            overflow: hidden;
            cursor: pointer;
            position: relative;
            .opacity(0);
            top: 30px;
            .transition(all ease-in-out .3s .15s);

            &.qnt {
                .opacity(1);
                top: 0px;
            }

            >span {
                top: -140px!important;
                .transition(all ease-in-out .3s);
            }

            &:hover {
                >span {
                    .transform(scale(1.05));
                }
            }

            &:nth-of-type(odd) {
                border-right: 24px solid @white;
            }

            .box {
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 20px 50px;
                cursor: pointer;

                .details {
                    text-transform: uppercase;
                    letter-spacing: .3em;

                    span {
                        color: @gold;
                        font-size: 1.5em;
                        vertical-align: middle;
                    }
                }

                h3 {
                    margin-top: 10px;
                    margin-bottom: 5px;
                    text-transform: uppercase;
                    letter-spacing: .3em;
                    color: @gold;
                    font-size: .75em;
                }
                h2 {
                    font-family: @serif;
                    font-size: 2.6em;
                    margin: 0;
                }
                a {
                    position: absolute;
                    text-decoration: none;
                    right: 50px;
                    top: 50%;
                    .transform(translateY(-50%));
                    font-size: 3.8em;
                    color: @gold;
                }
            }
        }
    }
}


/*come raggiungerci*/
.come-raggiungerci {
    min-height: 300px;
    background-color: @white;
    margin-top: 50px;
    margin-bottom: 50px !important;
    border: 1px solid @gold;

    .tab-control {
        padding: 0;
        height: 296px;

        .nav {
            float:none;
            margin:auto;
            height: 100%;

            li {
                height: 33.333%;

                .btn {
                    line-height: 100px;
                    background-color: @gold;
                    color:@white;
                    height: 100%;
                    padding: 0;
                    font-size: .8em;


                    &:hover {
                        background-color: lighten(@gold, 10%);
                    }
                }

                &.active {
                    .btn {
                        background-color: @white;
                        color: @gold!important;
                        font-size: 1.3em;
                    }
                }

                /*&:nth-child(1) {
                    .btn {
                        .box-shadow(inset -20px -20px 3px -20px @black);
                    }
                    &.active {
                        .btn {
                            .box-shadow(inset -20px -20px 0px -20px @black);
                        }
                        & + li .btn {
                            .box-shadow(inset -20px 20px 3px -20px @black);
                        }
                        & + li + li .btn {
                            .box-shadow(inset -20px 0px 3px -20px @black);
                        }
                    }
                }
                &:nth-child(2) {
                    .btn {
                        .box-shadow(inset -20px -20px 3px -20px @black);
                    }
                    &.active {
                        .btn {
                            .box-shadow(inset -20px -20px 0px -20px @black);
                        }
                        & + li .btn {
                            .box-shadow(inset -20px 20px 3px -20px @black);
                        }
                    }
                }
                &:nth-child(3) {}*/
            }
        }

        .nav-stacked > li + li {
            margin-top: 1px;
        }
    }

    .tab-content {
        /*border: 1px solid @gold;*/
        border: none;
        text-align: left;
        position: relative;
        font-size: .9em;
        .flexbox(column);
        align-self: center;
        padding-left: 5%;
        padding-right: 5%;

        .tab-pane {
            h4 {
                margin-top: 0;
                font-weight: 900;
                text-align: center;
                font-size: 3.6em;
                padding: 0 40px 20px;
                font-family: @serif;
                color: @gold;
            }
        }
    }
}

/*--------SERVIZI------------*/
.list-service {

        h4 {
            position: relative;
            cursor: pointer;
            text-align: center;
            font-size: 1.6em;
            color: fade(@gold, 70%);
            .transition(border ease-in-out .3s);
            border: 1px solid fade(@gold, 30%);
            padding: 15px;
            margin: 0;
            margin-top: 15px;

            .mobile & {
                font-size: 1.2em;
                padding: 15px 14%;
            }


            &:hover {
                // .transition(all ease-in-out .3s);
                border: 1px solid fade(@gold, 100%);
                color: fade(@gold, 100%);
            }

            &:focus {
                outline: inherit;
            }

            &:nth-child(1) {
                // border-top: none;
            }

            &:before {
                left: 30px;
                top: 50%;
                position: absolute;
                line-height: 0;
                font-size: 1.2em;
                .opacity(.7);
                .transition(all ease-in-out .3s);

                .mobile & {
                    left: 10px;
                }
            }

            &:after {
                font-family: "icomoon";
                content: "\e90f";
                // .transform(rotate(45deg));
                right: 30px;
                top: 50%;
                position: absolute;
                line-height: 0;
                font-size: 1.2em;
                .transition(all ease-in-out .3s);
                .opacity(.7);

                .mobile & {
                    right: 10px;
                }
            }
                &.ui-state-active {
                    color: fade(@gold, 100%);
                    border: 1px solid fade(@gold, 100%);
                    border-bottom: 1px solid fade(@gold, 10%);
                    .transition(border ease-in-out .2s .8s);

                    &:before {
                        .opacity(1);
                    }


                    &:after {
                        .transform(rotate(225deg));
                    }

                    .transition(border ease-in-out 0s);
                }
            }

        div {
            border: 1px solid fade(@gold, 100%);
            border-top: none;
            padding: 15px;
            text-align: center;
            overflow: hidden;

            a {
                .opacity(.7);
                
                /*display: inline-block;
                float: right;*/
                display: block;
                text-transform: uppercase;
                text-decoration: none;
                letter-spacing: .3em;
                font-size: .7em;
                font-weight: bold;
                position: relative;
                padding: 7px 10px;
                margin-top: 20px;
                margin-bottom: 20px;
                position: relative;
                right: 0;
                border: 1px solid fade(@gold, 30%);
                .transition(all ease-in-out .3s .1s);
                color: @gold;

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 50%;
                    width: 1000%;
                    margin-left: -1000%;
                    height: 1px;
                    background-color: fade(@gold, 30%);
                    .transition(all ease-in-out .3s .1s);
                }

                &:hover {
                    right: 20px;
                    border: 1px solid fade(@gold, 30%);
                }
            }

            >h3 {
                letter-spacing: .3em;
                text-transform: uppercase;
                font-size: .8em;
                color: @gold;
            }

            p {
                margin-top: 0;
                margin-bottom: 20px;
            }
        }
}


/*LISTA CAMERE*/

.list-block {

    .mobile & {
        > p {
            margin-right: 5%;
            margin-left: 5%;
        }
    }

    h5 {
        font-size: 30px;
        color: @gold;
        font-weight: lighter;
        margin-bottom: 15px;
        line-height: 60px;
        width: auto;
        text-transform: uppercase;

        .mobile & {
            text-align: center;
            font-size: 1.6em;


        }
    }

    &-service {
        #accordion,
        #accordion2 {
            margin-top: 0px;

            h3 {
                outline: none;
                border: 1px solid fade(@gold, 20%);
                font-size: 1em;
                padding: 15px 10px;
                margin: 15px auto;
                position: relative;
                .transition(all ease-in-out .3s);
                color: fade(@gold, 70%);

                &:before {
                    content: "\51";
                    font-family: "icomoon";
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    line-height: 0;
                    .transform(translateY(-50%));
                    vertical-align: middle;
                    font-size: 1em;
                    .transition(all ease-in-out .3s);
                }

                &:hover {
                    border: 1px solid fade(@gold, 100%);
                }

                &.ui-state-active {
                    border: 1px solid fade(@gold, 100%);
                    border-bottom: 1px solid fade(@gold, 20%);

                    &:before {
                        .transform(rotate(180deg));
                    }
                }
            }

            div {
                padding: 30px;
                background-color: transparent;
                margin-top: -15px;
                border: 1px solid darken(@gold, 40%);
                border-top: none;

                ul {
                    text-align: left;
                    margin: 20px auto;

                    li {
                        position: relative;
                        padding-left: 20px;

                        &:before {
                            content: "\48";
                            font-size: .8em;
                            font-family: "icomoon";
                            position: absolute;
                            left: 0;
                            top: 50%;
                            line-height: 0;
                        }
                    }
                }
            }
        }

        > ul {
            > li {
                min-height: 400px;
                background-color: @gold;
                .transition(all ease-in-out .3s);

                &:hover {
                    background-color: @gold;

                    *,
                    .block h3,
                    .block a {
                        color: #fff;
                    }
                    .block a.btn {
                        color: #fff;
                        background-color: @gold;
                    }
                }
                .background-cover {
                    background-position: 50%;
                }
                .block {
                    .transition(all ease-in-out .3s);
                    cursor: pointer;

                    h3 {
                        line-height: 1!important;
                        margin-bottom: 20px;
                        margin-top: 0px;
                    }
                    a {
                        color: @gold;
                    }
                }
            }
        }
    }

    &-service {
        .grid {
            @media all and (max-width: 1200px) {
                .grid-sizer,
                .grid-item {
                    width: 50%!important;
                }
            }
            .grid-sizer {
                width: 33.333%;
                margin-top: 20px;
            }
            .grid-item {

                width: 33.333%;
                padding: .333vw;
                .opacity(0);
                .transition(all ease-in-out .3s .15s);

                &.qnt {
                    .opacity(1);
                }

                &-inside {
                    position: relative;
                    padding: 40px;
                    border-top: 1px solid;
                    border-bottom: 1px solid;
                    border-right: 1px solid;
                    border-left: 1px solid;
                    border-color: @gold;
                    .transition(all ease-in-out .3s);
                    overflow: hidden;

                    &:before,
                    &:after {
                        content: "";
                        position: absolute;

                    }

                    &:before {
                        border: 1px solid lighten(@gold, 20%);
                        .transform(translateX(-50%));
                        left: 47px;
                        top: 0;
                        height: 50px;
                        width: 1px;
                    }
                    &:after {
                        border: 1px solid lighten(@gold, 10%);
                        .transform(translateX(-50%));
                        left: 47px;
                        top: 50px;
                        margin-bottom: -14px;
                        height: 14px;
                        width: 14px;
                        .border-radius(50%);

                    }

                    a {
                        color:@gold;
                    }

                    ul {
                        padding: 0;
                        li {
                            position: relative;
                            padding-left: 30px;

                            &:before {
                                position: absolute;
                                content: "";
                                border: 1px solid lighten(@gold, 10%);
                                .transform(translateY(-50%));
                                left: 0;
                                top: 50%;
                                margin-bottom: -14px;
                                height: 14px;
                                width: 14px;
                                .border-radius(50%);
                            }
                        }
                    }
                }

                &:hover {
                    .grid-item-inside {
                        border-color: darken(@gold, 10%);
                    }
                }

                .background-cover {
                    height: 240px!important;
                    background-size: cover;
                    clear: both;
                }
                .block {
                    text-align: left;
                    color: @black;
                    overflow: hidden;

                    h2 {
                        color: @gold;
                        font-size: 2.4em;
                        margin-top: 0;
                        margin-bottom: 10px;
                        font-family: @serif;
                        position: relative;
                        text-align: right;
                        .transition(all ease-in-out .3s);

                        &:hover {
                            color: @gold;
                        }
                    }
                    h3 {
                        color: @gold;
                        font-size: .9em;
                        letter-spacing: .3em;
                        text-transform: uppercase;
                        // letter-spacing: .3em;
                        text-align: right;
                        margin-top: 0;
                        position: relative;
                        padding-bottom: 20px;
                        margin-bottom: 20px;

                        &:after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            width: 100%;
                            height: 1px;
                            background-color: fade(@gold, 30%);
                        }
                    }

                    a {
                        display: inline-block;
                        float: right;
                        text-transform: uppercase;
                        text-decoration: none;
                        letter-spacing: .3em;
                        font-size: .7em;
                        font-weight: bold;
                        position: relative;
                        padding: 7px 10px;
                        margin-top: 20px;
                        margin-bottom: 20px;
                        /*margin-right: 0px;*/
                        position: relative;
                        right: 0;
                        border: 1px solid fade(@gold, 0%);
                        .transition(all ease-in-out .3s .1s);

                        &:before {
                            content: "";
                            position: absolute;
                            left: 0;
                            bottom: 50%;
                            width: 1000%;
                            margin-left: -1000%;
                            height: 1px;
                            background-color: fade(@gold, 30%);
                            .transition(all ease-in-out .3s .1s);
                        }

                        &:hover {
                            /*margin-right: 20px;*/
                            right: 20px;
                            border: 1px solid fade(@gold, 30%);
                        }
                    }
                }
            }
        }

        &-two-blocks {
            .grid {
                .grid-sizer {
                    width: 50%;
                }
                .grid-item {
                    width: 50%;

                    .background-cover {
                        height: 380px!important;
                    }
                }
            }
        }

        &-ristoranti {
            .grid {
                .grid-item {
                    padding: 20px;

                    &-inside {
                        background-color: @gold;
                        border: none;
                        height: 250px;
                        display: block!important;
                    }

                    &:nth-child(odd) {
                        .grid-item-inside {
                            background-color: @gold;
                        }
                    }

                    &:nth-child(8) {
                        left: 50%!important;
                        .transform(translateX(-50%));

                        .grid-item-inside {
                            background-color: @gold;
                        }
                    }
                }
            }
        }
        /*TOUR E ITINERARI*/
        &.no-icon {
            .grid .grid-item .grid-item-inside .block h3 {
                padding-left: 0;
                pointer-events: none;

                &:before {
                    content: none;
                }
            }
        }
        &.servizi {
            .grid .grid-item .grid-item-inside .block h3 {
                pointer-events: none;

                &:before {
                    content: "";
                }

                span:before {
                    font-size: 1em;
                    position: absolute;
                    line-height: 0;
                    left: 0;
                    top: 50%;
                }
            }
        }
    }

    > ul {
        padding-left: 0;

        > li {
            margin: 70px auto!important;
            display: -moz-box;
            display: -webkit-flexbox;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: -moz-flex;
            display: flex!important;
            -webkit-flex-direction: row;
            -moz-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;

            @media all and (max-width: 769px) {
                .tablet & {
                    -webkit-flex-direction: column;
                    -moz-flex-direction: column;
                    -ms-flex-direction: column;
                    flex-direction: column;
                }
            }

            &:first-child {
                margin: 0px auto!important;
            }

            // &.color-list:last-child {
            //     margin-bottom: 0!important;
            // }

            .background-cover {
                height: auto;
                background-size: cover;

                @media all and (max-width: 769px) {
                    .tablet & {
                        height: 320px;
                    }
                }
            }

            .block {
                padding: 100px 30px;
                color: @gold;

                @media all and (max-width: 1025px) {
                    padding: 50px 30px;
                }

                @media all and (max-width: 769px) {
                    .tablet & {
                        padding: 30px;
                    }
                }

                &:hover {
                    * {
                        color: #fff;
                    }
                    a.btn {
                        color: #fff;
                        background-color: @gold;
                    }
                }

                h3 {
                    color: @gold;
                    font-size: 2.2em;
                    line-height: 1;
                    text-transform: uppercase;
                }

                ul {

                    li {
                        display: inline-block;
                        position: relative;
                        margin: 0;

                        &:first-child {
                            margin-right: 15px;
                            &:after {
                                content: "-";
                                position: absolute;
                                right: -13px;
                                top: 0;
                            }
                        }

                        &:last-child {
                            display: none;
                        }

                        span {
                            &:last-child {
                                margin-right: 5px;
                            }

                            &:before {
                                vertical-align: middle;
                                bottom: 2px;
                                font-size: 1.2em;
                                position: relative;
                            }
                        }
                    }
                }

                .moduleItemIntrotext {
                    margin-bottom: 30px;
                }

                .moduleItemExtraFields {
                    display: inline-block;
                    margin: 40px auto 15px;
                    font-weight: bold;
                }
            }
        }

        // &-lifestyle {
        //     >ul>li {
        //         cursor: pointer;
        //         .block {
        //             padding: 45px 30px;
        //
        //             .moduleItemIntrotext {
        //                 margin-top: 30px;
        //             }
        //         }
        //     }
        // }

    }

    &.mobile {
        h3 {
            margin-top: 0;
            margin-bottom: 0;
            padding: 5px 10px;
            padding-right: 1em;
            outline-color: transparent;
            position: relative;
            text-transform: uppercase;
            .transition(all ease-in-out .3s);

            &:before {
                position: absolute;
                top: 50%;
                line-height: 0;
                right: 15px;
                font-family: "icomoon";
                content: "\51";
                .transition(transform ease-in-out .3s);
            }

            &.ui-state-active {
                &:before {
                    color: #fff;
                    .transform(rotate(540deg));
                }
            }
        }

        div {
            padding: 20px 10px;

            > span {
                display: inline-block;
                position: relative;

                p {
                    margin-bottom: 0;
                }

                &:first-child {
                    margin-right: 20px;

                    &:after {
                        content: "-";
                        position: absolute;
                        top: 10px;
                        right: -15px;
                        line-height: 0;
                    }
                }
            }
        }
    }
}


/*mappa */
.googlemap {
    margin: 0;
    margin-top:60px;
    .opacity(0);
    .transition(all ease-in-out .3s .1s);

    &.qnt {
        .opacity(1);
    }

    .phocamaps {
        background-color: white;
        width: 100%;
        margin-bottom: 50px !important;
        background-color: @black;
        /*border: 1px solid @gold;*/

        #phocamaps-box {
            margin-top: 0!important;
            float: left;
            margin: 0;
            width: 70%!important;

            @media all and (max-width: 1201px) {
                width: 100%!important;
            }
        }

        .pmroute {
            margin-left: 0;
            margin-top: 0;
            padding: 20px 30px;
            color: @gold;
            width: 30%;
            float: left;
            clear: none;
            height: 100%;
            display: table;
            text-align: center;

            @media all and (max-width: 1201px) {
                width: 100%;
                display: block;
            }

            > .form-inline {
                padding-top: 18%;
                display: table-cell;
                vertical-align: middle;
                font-weight: 500;
                font-size: 2.6em;
                font-family: @serif;
                color: @gold;

                @media all and (max-width: 1201px) {
                    font-size: 1.6em;
                    display: block;
                    padding-top: 0;
                }
            }

            form {
                .pm-input-route {
                    background-color:fade(@white, 5%);
                    border: none;
                    border-bottom: 2px solid @gold;
                    height: 40px;
                    vertical-align: top;
                    color: @white;
                    padding: 5px;
                    display: block;
                    margin: 10px 10%;
                    width: 80%;
                    border-radius: 0px;
                    font-size: .7em;
                    outline: none;

                    @media all and (max-width: 1023px) {
                        width: 90%;
                        margin: 10px 5%;
                    }

                    @media all and (max-width: 769px) {
                        margin: 10px auto;
                    }

                    .mobile & {
                        width: 100%;
                    }
                }

                .pm-input-route-btn {
                    color: @gold;
                    border: 3px solid #ffffff;
                    background: @black;
                    width: 80%;
                    font-size: .8em;
                    border:3px solid @gold;
                    font-size: 0.32em;
                    line-height: 1.5;
                    font-family: @sans-serif;
                    height: 60px;
                    outline: none;

                    @media all and (max-width: 1200px) {
                        font-size: .5em;
                    }
                    @media all and (max-width: 1023px) {
                        width: 90%;
                    }

                        &:hover {
                            color: @white;
                            background: @gold;
                            border:3px solid @gold;
                        }

                    .mobile & {
                        width: 100%;
                    }
                }
            }
        }

        #phocaDirPlgPM1 {
            background-color: lighten(@gold, 30%);
            border-top: darken(@gold, 30%);

            #phocaMapsPrintIconPlgPM1 {
                background-color: @black;

                .pmprintroutelink {
                    display: none;
                }
            }

            div {
                background-color: @white;
                div {
                    width: 80%;
                    margin: 0 10%;

                    .mobile &,
                    .tablet & {
                        @media all and (max-width: 769px) {
                            width: 95%;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
}
#phocamaps-box div.pmbox-grey div div div {
    padding: 0 !important;
}


/*-----mappa dove siamo-------------------------------------------------------*/
.mappainterattiva {
    padding: 0;
    height: 650px;
    float: none;
    position: relative;
    z-index: 0;
    margin-bottom: 30px;
    .opacity(0);
    .transition(all ease-in-out .3s .1s);

    &.qnt {
        .opacity(1);
    }

    @media all and (max-width: 769px) {
        height: auto;
        .flexbox(column);
    }

    @media all and (max-width: 769px) {
        .tablet & {
            display: block;
            height: auto;
        }
    }

    #interactive-map {
        padding: 0;
        height: 100%;

        @media all and (max-width: 769px) {
            .tablet & {
                display: block;
                height: 450px;
            }
        }

        #map {
            // min-height: 500px;
            // height: 100%;
        }
        @media all and (max-width: 769px) {
            display: block;
            height: 350px;
            min-height: none;
        }
    }

    #interactive-map #map {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%
    }

@media all and (max-width: 767px) {
    #interactive-map #map {
        left:0;
        position: relative;
        // height: 250px;
        top: 0;
        width: 100%
    }
}

#interactive-map #map .marker {
    text-align: center;
    transition: all .3s linear;
    -moz-transition: all .3s linear;
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear
}

#interactive-map #map .marker:before {
    position: relative;
    z-index: 2;
    top: 0;
    width: 60px;
    height: 60px;
    display: block;
}

#interactive-map #map .marker#hotel-marker:before {
    content: "";
    background-image: url(/images/marker/hotel-leonbianco-marker.png);
    z-index: 3;
    width: 128px;
    height: 128px;
}

#interactive-map #map .marker#Duomo-marker:before,
#interactive-map #map .marker#Grossa-marker:before,
#interactive-map #map .marker#Tortura-marker:before,
#interactive-map #map .marker#Agostino-marker:before,
#interactive-map #map .marker#Positano-marker:before,
#interactive-map #map .marker#Parcheggi-marker:before,
#interactive-map #map .marker#Volterra-marker:before,
#interactive-map #map .marker#Monteriggioni-marker:before,
#interactive-map #map .marker#Siena-marker:before,
#interactive-map #map .marker#Firenze-marker:before {
    content: "";
    background-image: url('/images/marker/hotel-leonbianco-POI.png');
    position: relative;
    z-index: 2;
    top: 0;
    width: 50px;
    height: 50px;
    display: block;
}

#interactive-map #map .marker.active:before {
    top: 0;
    background-image: url('/images/marker/hotel-leonbianco-POI-active.png')!important;
    z-index: 4;
    width: 50px!important;
    height: 50px !important;
}

#interactive-map .marker-poi {
    position: relative;
    width: 20%;
    z-index: 2;
    color: #fff;
    background-color: @gold;
    padding: 0;
    box-shadow: 0 0 24px rgba(74,49,49,0.5);
    -moz-box-shadow: 0 0 24px rgba(74,49,49,0.5);
    -webkit-box-shadow: 0 0 24px rgba(74,49,49,0.5)
}

@media all and (max-width: 1200px) {
    #interactive-map .marker-poi {
        width:40%
    }
}

#interactive-map .marker-poi .custom {
    height: 100%
}

#interactive-map .marker-poi .marker-link {
    margin: 0
}

#interactive-map .marker-poi .marker-link a {
    position: relative;
    z-index: 1;
    font-size: 1rem;
    font-weight: 100;
    color: #fff;
    display: block;
    padding: 15px 30px;
    border-bottom: 1px solid rgba(255,255,255,0.15);
    transition: all .3s linear;
    -moz-transition: all .3s linear;
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear
}

#interactive-map .marker-poi .marker-link a:before {
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -9px
}

#interactive-map .marker-poi .marker-link a strong {
    display: block;
    font-size: .9em;
    text-transform: uppercase
}

#interactive-map .marker-poi .marker-link a:hover,#interactive-map .marker-poi .marker-link a:focus {
    text-decoration: none;
    // background-color: rgba(208,70,68,0.5)
    // background-color: fade(@gold, 80%);
}

#interactive-map .marker-poi .marker-link.active a {
    // background-color: fade(@gold, 80%);
    z-index: 2;
    box-shadow: 0 0 24px rgba(74,49,49,0.5);
    -moz-box-shadow: 0 0 24px rgba(74,49,49,0.5);
    -webkit-box-shadow: 0 0 24px rgba(74,49,49,0.5)
}

#interactive-map .marker-poi .marker-link.active a:before {
    color: #4a3131
}

#interactive-map .marker-poi .marker-link:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    right: -100%;
    top: 0;
    background-position: center center;
    background-size: cover;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    zoom:1;transition: all .6s linear;
    -moz-transition: all .6s linear;
    -webkit-transition: all .6s linear;
    -o-transition: all .6s linear;
    border-right: 5px solid #d04644
}

@media all and (max-width: 1200px) {
    #interactive-map .marker-poi .marker-link:after {
        display:none
    }
}

#interactive-map .marker-poi .marker-link.active:after {
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    // filter: alpha(opacity=100%);
    zoom:1;
    }

    @media all and (max-width: 680px) {
        #interactive-map .marker-poi {
            width:100%
        }

        #interactive-map .marker-poi .marker-link {
            display: none;
            text-align: center
        }

        #interactive-map .marker-poi .marker-link.active {
            display: block
        }

        #interactive-map .marker-poi .marker-link.active a:after {
            position: absolute;
            bottom: -15px;
            left: 50%;
            margin-left: -7.5px;
            content: "";
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-top: 20px solid #d04644
        }

        #interactive-map .marker-poi .marker-link.active a:before {
            display: none
        }
    }
}

/*poi*/
.poi {
    background-color:@white;
    color: #fff;
    padding: 0;
    border:1px solid @gold;

    @media all and (max-width: 769px) {
        .tablet & {
            display: block;
            padding: 20px 0 0;
        }
    }

    > span {
        .opacity(.5);
        .transition(all ease-in-out .3s);
    }

    h3 {
       font-weight: 900;
       text-align: center;
       font-size: 3.6em;
       padding:  10px 20px 20px;
       font-family: @serif;
       color: @gold;
       border-bottom: 1px solid @gold;
    }

    > span {
            position: relative;
            z-index: 0;
    }

    .container-poi {
        position: relative;
        z-index: 1;

        h3 {
            text-align: center;
            font-size: 2.4vw;
            width: 90%;
            margin: 10px auto 0;

            @media all and (max-width: 767px) {
                width: 90%;
                font-size: 1.6em;
            }
        }

        .table {
            margin: 0px auto;
            margin-top: 20px;
            margin-left: 0;
            border-collapse: inherit;

            tr {
                border-bottom: 1px solid fade(#fff, 20%);
                border-left: 7px solid fade(@gold, 0%);

                &.marker-link.active {
                    border-bottom: 1px solid fade(#fff, 10%);
                    // border-left: 7px solid fade(@gold, 25%);
                    background-color: fade(#000, 10%);

                    td {
                        background-color: fade(@gold, 0%);

                        div a {
                            color: #fff;
                        }
                    }
                }

                &:last-child {
                        border-bottom: none;
                }

                td {
                    border: none;
                    padding: 8px 20px;
                    line-height: 1.1;

                    div {
                        a {
                            text-decoration: none;
                            .flexbox(row);
                            align-items: center;
                            height: 36px;

                            @media all and (max-width: 1100px) {
                                font-size: .9em;
                            }

                            span {
                                color: @gold!important;
                                display: inline;
                                width: 50%;
                                float: left;
                                font-weight: bold;

                                &:last-child {
                                    float: right;
                                    width: 50%;
                                    text-align: right;
                                    font-size: .8em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*calltoaction*/
.cta {
    // margin-top: 30px;
    height: 400px!important;
    overflow: hidden;

    >span {
        background-color: fade(@black, 50%);
        background-blend-mode: multiply;
    }

    .item {
        text-align: center;
        position: absolute;
        width: 25%;
        top: 60%;
        left: 50%;
        .transform(translate(-50%, -50%));
        position: relative;
        .opacity(0);
        .transition(all ease-in-out .3s);

        &.qnt {
            .opacity(1);
            top: 50%;
        }

        p {
            width: 100%;
            display: block;
            margin: 30px auto;

            a {
                position: relative;
                overflow: hidden;
                .transition(all ease-in-out .3s);
                font-weight: bold;
                text-transform: uppercase;
                width: 100%;
                margin: 0;
                display: block;
                font-size: .9em!important;
                height: 60px!important;
                line-height: 54px!important;
                background-color: transparent;
                color: fade(@white, 90%)!important;
                border: 3px solid fade(@white, 90%);
                text-decoration: none;

                &:before {
                    line-height: 54px!important;
                    .transition(all ease-in-out .3s);
                    position: absolute;
                }

                &:hover {
                    border: 3px solid @white;
                    background-color: @white;
                    color: @black!important;
                }
            }
        }
    }
}

/*recensione*/
.review {
    margin: 30px auto;

    @media all and (max-width: 1441px) {
        .container-full {
            height: 25vw;
        }
    }

    .item {
        display: block;
        width: 70%;
        position: relative;
        .opacity(0);
        .transition(all ease-in-out .3s .15s);

        h3 {
            text-transform: uppercase;
            font-size: .8em;
            letter-spacing: .3em;
            color:@gold;
            text-align: left !important;
        }

        blockquote {
            border: none;

            h2 {
                font-family: @serif;
                font-size: 2.8em;
                margin-bottom: 60px;
                padding-bottom: 30px;
                text-align: left !important;

                @media all and (max-width: 1441px) {
                    font-size: 3vw;
                }
            }

            sub {
                position: absolute;
                right: 12%;
                font-size: 50%;
            }
        }
    }

    &.qnt {
        .item {
            left: 0;
            .opacity(1);
        }
    }
}

/*blocco testo*/
.block-text {
    padding: 50px 0px;

    &.faq {
        margin-top: -60px;
    }

    &.qnt {
        .container,
    .container-full {
            top: 0;
            .opacity(1);

                .item {
                    top: 0;
                    .opacity(1);
                }
            }
        } 

    @media all and (max-width: 767px) {
        margin-top: 0;
    }

    .container,
    .container-full {
        position: relative;
        top: 30px;
        .opacity(0);
        .transition(all ease-in-out .3s .2s);

        h3 {
            font-size: 2em;
            color: @gold;
            border-top: 1px solid fade(@black, 30%);
            padding-top: 15px;
            font-family: @serif;
            font-weight: 400;

            &:first-child {
                border: none;
            }

            &:before {
                margin-right: 15px;
                vertical-align: middle;
            }
        }

        .item {
            h3 {
                margin-top: 0;
                padding-top: 0;
            }

            position: relative;
            top: 30px;
            .opacity(0);
            .transition(all ease-in-out .3s);
        }

        ul {
            li {
                list-style-type: none;
            }
        }
    }
}




/*gallery page*/
.row {
    h3 {
        font-family: @serif;
        color: @gold;
        font-weight: 700;
        font-size: 2em;
        position: relative;
        border-top: 1px solid rgba(99, 102, 106, 0.5);
        padding-top: 15px;
    }

}

.gallery-page {

    @media all and (max-width: 769px) {
        margin-top: -40px;

        .tablet & {
            margin-top: 70px;
        }
    }

    .background-cover {
        padding: 0;
        background-color: @black;
        overflow: hidden;
        position: relative;
        top: 30px;
        .opacity(0);
        .transition(all ease-in-out .3s);

        &.qnt {
            top: 0px;
            .opacity(1);
        }

         >span {
            .transition(all ease-in-out 1s);
            position: relative;

            &:after {
                font-family: "icomoon";
                content: "\e908";
                font-size: 5em;
                color: fade(@white, 70%);
                background: transparent;
                .opacity(0);
                position: absolute;
                z-index: 1;
                left: 50%;
                bottom: -50%;
                cursor: pointer;
                .transform(translate(-50%, 50%));
                .transition(all ease-in-out .3s);
                // text-shadow: 1px 1px 1px fade(@black, 50%), 0 0 40px fade(@black, 50%);

                .mobile &,
                .tablet & {
                    bottom: 50%;
                    .opacity(0);
                }
            }
        }


        &:hover {
             >span {
                // .transform-multiple(scale(1.1), rotate(-1deg));

                &:after {
                    bottom: 50%;
                    .opacity(1);
                }
            }
        }



        // &:nth-child(4n) {
        //     .image-cover {
        //         border-right: none;
        //     }
        // }

        .image-cover {
            position: relative;
            z-index: 0;
            left: 0;
            top: 0;
            height: 15vw;
            float: left;
            background-size: cover;
            background-position: center center;
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            overflow: hidden;

            .mobile & {
                height: 45vmax;
            }

            &:before {
                background-color: #fff;
            }


            // @media all and (max-width: 769px) {
            //     width: 100vw;
            //     height: 100vw;
            //     border-right: none;
            //
            //     .tablet & {
            //         width: 50vw;
            //         height: 50vw;
            //     }
            //
            //     .mobile & {
            //         height: 45vmax;
            //     }
            //
            //     &:first-child {
            //         .tablet & {
            //             border-right: 2px solid #fff;
            //         }
            //     }
            // }
            @media all and (min-width: 769px) and (max-width: 991px) {
                height: 25vw;
            }
            @media all and (min-width: 992px) and (max-width: 1023px) {
                height: 25vw;
            }
            @media all and (min-width: 1024px) and (max-width: 1200px) {
                height: 30vw;
            }

            // &:hover {
            //     .opacity(.9);
            // }
        }

        // @media all and (orientation: landscape) {
        //     &.col-xs-12 {
        //         .mobile & {
        //             width: 50%;
        //             border-right: 2px solid @white;
        //
        //             .image-cover {
        //                 width: 50vw;
        //             }
        //         }
        //     }
        //
        //     &:nth-child(2n) {
        //         .mobile & {
        //             .image-cover {
        //                 border-right: none;
        //             }
        //         }
        //     }
        // }
    }
}

/*--------OFFERS dettaglio---------------------*/
.itemView.dett-blog .itemBody.background-cover  {
>span {
  .opacity(1) !important;
}
}

.itemView {
&.dett-blog,
&.dett-offers {
    .condizioni{
        em{
            border-top: 1px solid @gold;
            padding-top: 20px;
            margin-top: 20px;
            display: block;

        }
    }
    overflow: hidden;
    align-items: flex-start!important;

    @media all and (max-width: 1200px) {
        .flexbox(column)!important;
    }

    .tablet & {
        @media all and (orientation: portrait) {
            .flexbox(column)!important;
        }
    }

    + div {
        margin-bottom: 30px;
    }

    > div {
        padding: 10px 50px 0px;

        .tablet & {
            padding: 10px 20px 0px;
        }
        .mobile & {
            padding: 10px 0px 0px;
        }
    }

    .itemHeader {
        width: 100%;
    }

    .itemFullText {
        margin-bottom: 10px;

        img {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    ul {
        list-style-type: none;
        padding-left: 0;

        li {
            font-weight: bold;
            color: @white;
            background-color: darken(@gold, 10%);
            padding: 3px 8px;
            display: inline-block;
            margin: 5px;
            // .transition;

            a {
                color: @white;
                text-decoration: underline;
            }

            &:hover {
                background-color: darken(@gold, 25%);
            }
        }
    }

    .itemExtraFields,
    .itemBody {
        border-top: none;
        padding-top: 0px;


        @media all and (max-width: 1200px) {
            width: 100%;
        }

        .tablet & {
            @media all and (orientation: portrait) {
                width: 100%;
            }
        }
        h2{
            font-family: @serif;
            color:@white;
            font-size: 2.6vw;
            margin-bottom: 0px;
            margin-top: 0px;

        }
        h3 {
            margin: 20px 0px!important;
            color: @white;
            text-align: center;
            font-family: @serif;
            font-size: 1.7em;
        }
        .btn{
            margin-top: 40px;
        }

        .text-offer{
            padding-right: 50px;
            text-align: left;
            padding-top: 50px;

            h2{
                font-family: @serif;
                color:@gold;
                font-size: 2.6vw;
                margin-bottom: 30px;

            }

            h3 {
                text-transform: uppercase;
                margin: 20px 0px!important;
                font-size: 1.6em;
                line-height: 1;
                font-family: @serif;
            }
            h2.itemTitle {
                margin-bottom: 30px;
                text-align: center;
            }
            h4{
                color: @black;
                text-align: left;
                font-family: @serif;
                font-size: 1.7em;
                border-top: 1px solid @gold;
                padding-top:20px;
            }
            p{
                text-align: left!important;
            }
        }
    }

    .text {
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        padding: 0 60px;
        top:50px;

        .mobile & {
            padding: 0 10px;
        }

        .itemExtraFields {
            position: relative;
            z-index: 0;
            color: @white;
            margin: 0!important;

            .block {
                margin: 0px 0px 20px;

                >a.btn {
                    color: @white;
                    border-color: @white;

                    .mobile & {
                        padding-left: 5px;
                        padding-right: 5px;
                    }

                    &:hover {
                        color: @black;
                        background-color: @white;
                    }
                }

                &.vantaggi {
                    div {
                        p {
                            line-height: 1.2em;
                            margin: 15px 0px;
                        }

                        &:before {
                            color: @white;
                            font-size: 1.2em;

                            .mobile & {
                                left: 5px;
                                top: 0;
                                position: absolute;
                            }
                        }
                    }
                }
                h2{
                    span {
                    color: @white;

                    }

                }
            }
        }
    }

    .itemBody {
        @media all and (max-width: 1200px) {
            width: 100%;
        }

        .tablet & {
            @media all and (orientation: portrait) {
                width: 100%;
            }
        }

        &.background-cover {
            background-color: @black;
            height: 500px;

            .mobile & {
                height: 200px;
            }

            >span {
                background-color: fade(@black, 40%);
                background-blend-mode: multiply;
                // .transition;

            }
             // &:hover {
             //     >span {
             //         background-color: fade(@black, 40%);
             //         background-blend-mode: multiply;
             //     }
             // }
        }
        .itemImageBlock {
            height: 350px;
            margin-bottom: 25px;

            div {
                height: 350px;
            }
        }
    }

    .itemFullText {
        text-align: left;

        h3 {
            margin-top: 0px;
        }
    }
}

&.dett-offers  {
    .itemBody.background-cover {
        .mobile & {
            height: 450px;
        }
    }
}

&.dett-blog {
    .itemHeader {
        h2 {
            font-size: 2.6em;
            margin-bottom: 15px;
        }
    }
}
}

/*chronoform*/
a.chronoforms6_credits {
    display: none;
}

.chronoforms {
    .opacity(0);
    .transition(all ease-in-out .3s);

    &.qnt {
       .opacity(1); 
    }

    .ui.form .field>label {display: none;}

    font-family: @serif;

    .ui.form {
        .field {
            input:not([type]),
            input[type=date],
            input[type=datetime-local],
            input[type=email],
            input[type=file],
            input[type=number],
            input[type=password],
            input[type=search],
            input[type=tel],
            input[type=text],
            input[type=time],
            input[type=url],
            input[type=checkbox],
            textarea {
               border: none;
               .border-radius(0);
               border-bottom: 3px solid  @gold;
               color: @black;
               font-weight: bold;
               font-family: @serif;
               font-size: 1.2em;

               &:focus {
                   border-color: @gold;
                   .border-radius(0);
                   color: @black;
               }

               &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
               color: fade(@black, 70%);
               font-weight: bold;
               opacity: 1; /* Firefox */
               }

               &:-ms-input-placeholder {
               color: @black;
               }

               &::-ms-input-placeholder {
               color: @black;
               }
            }

            &.error {
                input:not([type]),
                input[type=date],
                input[type=datetime-local],
                input[type=email],
                input[type=file],
                input[type=number],
                input[type=password],
                input[type=search],
                input[type=tel],
                input[type=text],
                input[type=time],
                input[type=url],
                input[type=checkbox],
                textarea {
                    background-color: @white;

                    &:focus {
                        background-color: @white;
                    }
                }
            }

            .ui.checkbox .box:before, .ui.checkbox label:before {
                .border-radius(0);
                border: 2px solid @gold;
            }
            .ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after {
                color: @white;
                background-color: @gold;
            }
            #btnPrivacy {
                color: @gold!important;
                margin-top: 50px;
            }
        }
    }

    .ui.button {
        .border-radius(0)!important;
        padding: 1.2em 3.2em;
    }

    // .semanticui-body button, .semanticui-body input, .semanticui-body optgroup, .semanticui-body select, .semanticui-body textarea {
    //     font-family: @serif;
    // }
}

/*gallerie in pagine */
.galleria-item{
        padding-top: 30px;
        padding-bottom: 30px;
}