html,
body {
    font-size: 17px;
    line-height: 1.5;
    font-family: @sans-serif;
}

body {
    background-color: #eee;
}

/* --- CHRONOFORM ----------------------------------------------------------- */
.chrono_credits { display: none; }

/* --- BANNER COOKIES ------------------------------------------------------- */
#banner-cookies {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    margin-bottom: 0;
    z-index: 10;
    font-size: .8rem;
    line-height: 1;
    color: #fff;
    background-color: fade(@black, 100%);
    padding: 0px 15px;
    .border-radius(0);

    .affix & {
        display: none;
    }
    .no-background & {
        position: fixed;
        bottom: 0;
        display: block;
        top: auto;
        background-color: fade(@black, 90%);
        border-top: 1px solid lighten(@black, 20%);
    }

    p {
        margin-right: 20vw;
        margin-top: 7px;
        margin-bottom: 7px;
        font-size: .8em;

        a {
            color: #fff;
        }
    }

    .btn {
        background-color: lighten(@black, 20%);
        border: none;
        .border-radius(0);
        font-size: .9em;
        padding: 0 15px;
        line-height: 25px;
        height: 100%;
        right: 0;
        bottom: 0;
        position: absolute;
    }
}
