@media all and (max-width: 1681px) {
    header {
        .navbar-default {
            .container-full {
                .navbar-collapse {
                    .navbar-nav {
                        >li {
                            >a {
                                padding: 13px 18px;
                            }

                            &.dropdown {
                                a:before {
                                    right: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.affix {
            .navbar-default {
                .container-full {
                    .navbar-collapse {
                        .navbar-nav {
                            font-size: .7em;

                            >li {
                                >a {
                                    padding: 13px 15px;
                                }

                                &.dropdown {
                                    a:before {
                                        right: 3px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .booking {
        left: 10%;
        width: 80%;
    }

    main#content #k2Container .itemBody .container-full .subTitle {
        font-size: 1.8vw;
    }

    main#content #k2Container.room-detail .services {
        >h3 {
            font-size: 1.8vw;
        }

        ul {
            column-gap: 50px;
        }
    }
}
@media all and (max-width: 1441px) {
    header {
        .navbar-default {
            .container-full {
                .navbar-collapse {
                    .navbar-nav {
                        >li {
                            >a {
                                padding: 13px 15px;
                            }

                            &.dropdown {
                                a:before {
                                    right: 3px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.affix {
            .navbar-default {
                .container-full {
                    .navbar-collapse {
                        .navbar-nav {
                            font-size: .7em;

                            >li {
                                >a {
                                    padding: 13px 12px;
                                }

                                &.dropdown {
                                    a:before {
                                        right: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .booking {
        left: 10%;
        width: 80%;
    }

    .list-offers .container-full .swiper-wrapper .swiper-slide {
        height: 300px;
    }

    .list-rooms ul li {
        >span {
            top: -120px!important;
        }

        .box {
            font-size: .8em;
        }
    }

    .list-article .k2ItemsBlock .container-full ul li .text {
        &:before,
        &:after {
            left: -30px;
        }
    }

    main#content #k2Container.room-detail .services {
        >h3 {
            font-size: 1.8vw;
            padding-left: 0;
            margin-right: 50px;
        }

        ul {
            column-gap: 50px;

            li {
                font-size: .9em;
            }
        }
    }

    .cta .item {
        width: 35%;
    }
}

@media all and (max-width: 1369px) {
    header {
        .navbar-default {
            .container-full {
                margin: 0px 2.5%;

                .navbar-collapse {
                    .navbar-nav {
                        font-size: .7em;

                        >li {
                            >a {
                                padding: 13px 13px;
                            }

                            &.dropdown {
                                a:before {
                                    right: 2px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .booking {
        left: 10%;
        width: 80%;
    }
}

@media all and (max-width: 1281px) {
    header {
        .navbar-default {
            .container-full {
                margin: 0px 0%;

                .navbar-collapse {
                    padding-left: 0;
                    padding-right: 0;

                    .navbar-nav {
                        font-size: .6em;

                        >li {
                            >a {
                                padding: 13px 10px;
                            }

                            &.dropdown {
                                a:before {
                                    right: 0px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.affix {
            .navbar-default {
                .container-full {
                    .navbar-collapse {
                        .navbar-nav {
                            font-size: .545em;

                            >li {
                                >a {
                                    padding: 13px 9px;
                                }

                                &.dropdown {
                                    a:before {
                                        right: -2px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .booking {
        left: 5%;
        width: 90%;
    }

    .block-4.social-home .background-cover {
        height: 220px;
    }

    .list-rooms ul li .box {
        font-size: .7em;
        padding: 15px 30px;

        a {
            right: 30px;
        }
    }

    .list-rooms ul li {
        >span {
            top: -100px!important;
        }

        .box {
            font-size: .7em;
            padding: 15px 30px;

            a {
                right: 30px;
            }
        }
    }

    main#content #k2Container.room-detail .services {
        >h3 {
            font-size: 1.8vw;
            padding-left: 0;
            margin-right: 10px;
            width: 35%;
        }

        ul {
            column-gap: 20px;
            width: 65%;

            li {
                font-size: .7em;
            }
        }
    }

    .cta {
        height: 320px!important;

        .item {
            width: 45%;
        }
    }
}

/*tablet | mobile*/
@media all and (max-width: 1024px) {
    .tablet,
    .mobile {
        body {
            font-size: 15px;
        }

        .swiper-container-background .swiper-slide .claim {
            width: 75%;

            h2 {
                font-size: 4.6vmax;
            }
        }

        main#content #k2Container {
            
            h2.itemTitle {
                font-size: 1.5vmax;
            }

            .itemBody {
                .container-full {
                    margin: 30px auto;
                    .flexbox(column);

                    .buttonDX .btn {
                        right: 0!important;
                    }

                    .subTitle {
                        font-size: 3.4vmax;
                    }
                }
            }
        }

        .block-full .container, 
        .block-full .container-full {
            /*height: 30vmax;*/
            height: auto;

            .item {
                .transform(translateY(0));
                top: auto;

                >h3 {
                    font-size: 1.2vmax;
                }

                >h2 {
                    font-size: 3.2vmax;
                    margin-bottom: 30px;
                }
            }
            
        }

        .article-home {
            margin-bottom: 50px;

            >h2 {
                font-size: 2.6vmax;
            }

            >h3 {
                font-size: 1.2vmax;
            }

            .item {
                width: 100%;
                height: 40vmax;
                margin-bottom: 1vmax;

                &:nth-child(1) {
                    margin-right: 0;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    height: 40vmax;
                    width: 100%;
                }

                .text {
                    width: 90%;

                    >h3 {
                        font-size: 2.2vmax;
                    }
                    >h2 {
                        font-size: 2.4vmax;
                    }

                    >blockquote {
                        font-size: 2.4vmax;
                    }
                }
            }
        }

        .titleModules {
            &.marginTop {
                margin-top: 20px;
            }
            &.marginBottom {
                margin-bottom: 20px;
            }

            >h2 {
                font-size: 3.6vmax;
            }
            >h3 {
                font-size: 1.5vmax;
            }
        }

        .list-dintorni-home .k2ItemsBlock .container-full ul li {
            width: 100%;
            height: 40vmax;
            margin-bottom: 1vmax;

            &:nth-child(2n+1) {
                margin-right: 0;
            }

            .text {
                width: 90%;
                text-align: center;

                >h2 {
                    font-size: 3vmax;
                    margin-top: 0;
                }
                >h3 {
                   font-size: 1.8vmax; 
                }

                a.btn {
                    margin-top: 15px;
                }
            }
        }

    footer {
        .link-footer {
            flex-wrap: wrap;

            >p a {
                font-size: .65em;
            }
        }

        .dati-hotel,
        .credits {
            text-align: center;
        }
    }

    .block-full.review {
        .item {
            width: 100%;

            h3 {
                font-size: 1.5vmax;
            }
            blockquote {
                h2 {
                    font-size: 4.2vw
                }

                sub {
                    font-size: 75%;
                }
            }
        }
    }

    main#content #k2Container.room-detail {
        .itemBody {
            .flexbox {
                .flexbox(column);

                .itemTitle {
                    top: auto;
                    right: 50px;
                }

                .subTitle {
                    margin-top: 50px;
                }
            }
        }

        .itemImageGallery.swiper-container-room {
            width: 100%!important;
            height: 35vmax;

            .swiper-button {
                left: 10px;
                bottom: 10px;
            }
        }

        .blocco-full {
            height: 35vmax;

            .text {
                h3 {
                    font-size: 0.8em;
                }
                h2 {
                    font-size: 5vmax;
                }
                a.btn {
                    margin-top: 25px;
                }
            }
        }

        .services {
            flex-wrap: wrap;
            padding: 5px;

            >h3 {
                font-size: 3.2vmax;
                padding-left: 5%;
                width: 90%;
            }

            ul {
                column-gap: 10px;
                width: 100%;

                li {
                    line-height: 1.5em;
                    padding-left: 20px;
                    margin-bottom: 10px;
                }
            }
        } 
    }

    .cta {
        height: 35vmax;
        .item {
            position: absolute;
            width: 90%;
        }
    }

    .list-article {
        .k2ItemsBlock {
            .container-full { 
                ul {
                    li {
                        height: 65vmax;
                        width: 100%;
                        margin-bottom: 1vw;

                        &:nth-child(2n+1) {
                            margin-right: 0;
                        }
                        &:nth-child(even) {
                            .transition(all ease-in-out .3s .2s);
                        }

                        .text {
                            width: 95%;
                            font-size: 2vmax;
                            line-height: 2.4vmax;

                            >h2 {
                                font-size: 3.2vmax;
                            }

                            .wrap_text {
                                padding: 10px 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    .come-raggiungerci {
        .flexbox(column);

        .tab-control {
            .nav {
                .flexbox(row);
                justify-content: center;
                height: auto;
                width: 100%;

                li {
                    height: auto;
                    width: 33.333%;

                    &.active .btn {
                        font-size: 1.1em;
                    }

                    &:nth-child(2) {
                        border-left: 1px solid lighten(@gold, 15%);
                        border-right: 1px solid lighten(@gold, 15%);
                    }
                }
            }

            .nav-stacked > li + li {
                margin-top: 0;
            }

            height: auto;
        }

        .tab-content {
            margin-top: 20px;
        }
    }

    .googlemap {
        .opacity(1);
    }

    body.no-background main#content {
        padding-top: 80px;
    }

    .block-text .container, 
    .block-text .container-full {
        .opacity(1);
    }

    .itemView.dett-blog .itemBody .text-offer, 
    .itemView.dett-blog .itemExtraFields .text-offer, 
    .itemView.dett-offers .itemBody .text-offer, 
    .itemView.dett-offers .itemExtraFields .text-offer {
        padding-right: 3%;
        padding-left: 3%;

        h2 {
            font-size: 6vmax;
            margin-bottom: 20px;
        }

        h4 {
            font-size: 1.4em;
        }
    }

    .list-offers .container-full .swiper-wrapper .swiper-slide {
        padding: 20px;
        overflow: hidden;

        h2 {
            font-size: 4vmax;
        }
    }
}
    .mobile {
        .block-4.social-home .background-cover {
            border-right: none;
            border-bottom: 1vmax solid @white; 
            height: 22vmax;

            &:nth-child(2n) {
                border-left: 1vmax solid @white;
            }
        }

        .list-rooms {
            ul {
                li {
                    height: 45vmax;

                    &:nth-of-type(odd) {
                        border-right: none;
                    }

                    .box {
                        padding: 15px;
                        font-size: .6em;

                        a {
                            right: 5px;
                        }
                    }

                    >span {
                        top: -80px!important;
                    }
                }
            }
        }
    }
    .tablet {
        .swiper-container-background {
            height: 100vh!important;
        }
        .incentivi {
            padding: 10px 0;
        }
        .block-4.social-home .background-cover {
            border-right: none;
            border-left: none;
            border-bottom: 1vmax solid @white; 
            height: 16vmax;

            &:nth-child(2), 
            &:nth-child(3), 
            &:nth-child(4) {
                border-left: 1vmax solid @white;
            }
        }
        .list-rooms {
            ul {
                li {
                    height: 45vmax;

                    &:nth-of-type(odd) {
                        border-right: none;
                    }

                    .box {
                        padding: 15px;
                        font-size: 1em;

                        a {
                            right: 15px;
                        }
                    }

                    >span {
                        top: -80px!important;
                    }
                }
            }
        }    

        main#content #k2Container.room-detail .services ul {
            padding-left: 20px;

            li {
                font-size: 1em;
            }
        }

        

        .gallery-page .background-cover .image-cover {
            height: 25vmax;
        }
    }

    body.no-background.tablet main#content {
            padding-top: 80px;
    }
}

@media all and (max-width: 1023px) {

header .navbar-default {
            border-top: 1px solid fade(@white, 20%);

            .container-full {
                .navbar-collapse {
                    margin-left: 0;
                    margin-right: 0;
                    overflow-x: hidden;
                    overflow-y: scroll;
                    justify-content: start;
                    background-color: @black;
                    height: 100vh;

                    .navbar-nav {
                        padding: 0px;
                        margin-top: 0;
                        display: block;
                        font-size: .8em;
                    }

                    .navbar-nav>li.dropdown a:before {
                        right: 10px;
                    }
                }
            }
        }

        header.affix .navbar-default .container-full .navbar-collapse .navbar-nav {
            font-size: .8em;
        }

        header.affix {
            .navbar-default {
                background-color: @black;
            }
        }

        header .navbar-default .container-full .navbar-collapse .navbar-nav {
            >li >a {
                font-size: 1.3em;
                line-height: 2.5em;
                padding: 13px 20px;
            } 
            
            >li.dropdown .dropdown-menu >li >a {
                font-size: 1em;
            }

            >li.dropdown a:before {
                right: 30px;
            }  
        }
}