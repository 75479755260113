/*main content*/
main#content {
    background-color: @white;

    body.no-background & {
        padding-top: 197px;
    }

    .content-text {
    }

    #k2Container {
        padding: 0;
        margin: 0;

        &.no-article {
            .itemBody {
                display: none;
            }
        }

        h2.itemTitle {
            font-family: @sans-serif;
            color: @gold;
            font-size: 1vw;
            text-transform: uppercase;
            letter-spacing: .3em;
            margin-top: 100px;
            margin-bottom: 20px;
            .transition(all ease-in-out .3s .15s);
            position:relative;
            .opacity(0);
            top: 20px;

            @media all and (max-width: 1369px) {
                font-size: 1.2vw;
            }

            &.qnt {
                .opacity(1);
                top: 0px;
                
                &:before {
                    height: 65px;
                }
                &:after {
                    top: -35px;
                    .opacity(1);
                }
            }

            &:before {
                content: "";
                position: absolute;
                left: 50%;
                .transform(translateX(-50%));
                top: -100px;
                height: 0px;
                width: 1px;
                background-color: @gold;
                .transition(all ease-in-out .4s .2s);
            }
            &:after {
                content: "";
                position: absolute;
                left: 50%;
                .transform(translateX(-50%));
                top: -105px;
                height: 14px;
                width: 14px;
                .border-radius(50%);
                border: 1px solid @gold;
                .opacity(0);
                .transition(all ease-in-out .4s .2s);
            }
        }
        .itemBody {
            background-color: @white;

            @media all and (max-width: 1200px) {
                &:first-child {
                    margin-bottom: 30px;
                }
            }

            &.qnt .container-full {
                .subTitle {
                    .opacity(1);
                    left: 0;
                }
                .itemFullText {
                    line-height: 2em;
                    .opacity(1);
                    top: 0;
                }
                .buttonDX {
                    .btn {
                        .opacity(1);
                        right: 0;
                    }
                }
            }

            .container-full {
                align-items: center;
                margin: 70px auto;

                .subTitle {
                    color: @black;
                    text-align: left;
                    font-family: @serif;
                    font-size: 1.8em;
                    padding: 0 0 0 2%;
                    .transition(all ease-in-out .3s);
                    .opacity(0);
                    position: relative;
                    left: -30px;

                    &:first-letter {
                        color: @gold;
                        // font-size: 2.8em;
                        vertical-align: text-top;
                        // line-height: 1;
                        float: left;
                        // margin-top: .05em;
                        margin-right: .05em;
                        font-size: 2.5em;
                        line-height: 0;
                        margin-top: .11em;
                    }

                    h3 {
                        font-family: @sans-serif;
                        color: @gold;
                        font-weight: 700;
                        font-size: 2em;
                        position: relative;
                        text-align: right;
                        right: 25%;
                    }

                    h4 {
                        text-transform: uppercase;
                        color: @gold;
                        font-weight: bold;
                        text-align: right;
                        font-size: .9em;
                        position: relative;
                        text-align: right;
                        right: 25%;
                    }
                    a{
                        color: @gold;
                    }
                }
                .itemFullText {
                    text-align: left;
                    line-height: 0em;
                    padding: 0 2%;
                    .transition(all ease-in-out .3s);
                    .opacity(0);
                    position: relative;
                    top: 20px;

                    &.align-self-baseline {
                        align-self: baseline;
                    }

                    &.colDX {
                        align-self: baseline;
                    }

                    a {
                        color: @gold!important;
                    }
                    h4 {
                        font-family: @sans-serif;
                        color: @gold;
                        font-weight: 700;
                        font-size: 1.2em;
                        position: relative;
                        border-bottom: 1px solid rgba(99, 102, 106, 0.5);
                        padding-bottom: 15px;
                    }

                    h3 {
                        font-size: 2em;
                        color: @gold;
                        border-top: 1px solid fade(@black, 30%);
                        padding-top: 15px;
                        font-family: @serif;
                        font-weight: 400;

                        &:first-child {
                            border: none;
                        }

                        &:before {
                            margin-right: 15px;
                            vertical-align: middle;
                        }
                    }

                    ul {
                        li {
                            list-style-type: none;
                        }
                    }
                }

                .buttonDX {
                    .btn {
                        position: relative;
                        .opacity(0);
                        right: -80px;
                    }
                }
            }
        }

        // DETTAGLIO CAMERE
        &.room-detail {
            .itemBody {
                padding: 0;

                .container-full {
                    margin: 0 auto;
                    width: 100%;
                }

                &.qnt {
                    .itemImageGallery {
                        .opacity(1);
                    }
                }
            }

            h2.itemTitle {
                position: absolute;
                top: 50px;
                right: 50%;
                margin-top: 0;
                margin-bottom: 0;
                margin-right: 120px;

                &:before {
                    content: "";
                    position: absolute;
                    left: auto;
                    right: -240px;
                    top: 50%;
                    width: 140px;
                    height: 1px;
                    background-color: @gold;
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    .transform(translateY(-50%));
                    left: auto;
                    right: -30px;
                    height: 14px;
                    width: 14px;
                    .border-radius(50%);
                    border: 1px solid @gold;
                }
            }

            .subTitle {
                padding: 0 10%!important;
            }

            .itemImageGallery {
                height: 30vw;
                margin: 0;
                z-index: 0!important;
                .transition(all ease-in-out .3s);
                .opacity(0);

                a.background-cover {
                    position: relative;

                    .image-cover {
                        width: 100%!important;
                        // height: 17.5vw!important;
                    }
                }

                &.swiper-container-room {
                    height: 35vw;
                    position: relative;
                    width: 50%!important;

                    .swiper-wrapper {
                        .swiper-slide {
                            background-color: @black;
                            position: relative;

                            &:before {
                                position: absolute;
                                font-family: "icomoon";
                                content: "\e90a";
                                top: 50%;
                                left: 50%;
                                font-size: 6vw;
                                .transform(translate(-50%, -50%));
                                color: fade(@white, 60%);
                            }

                            a {
                                &:hover {
                                    >span {
                                        .opacity(.75);
                                        .transform(scale(1.05));
                                    }
                                }

                                >span {
                                    .transition(all ease-in-out .3s);
                                }
                            }
                        }
                    }

                    .swiper-button {
                        width: 100%;
                        position: absolute;
                        bottom: 20px;
                        left: 20px;

                        .swiper-button-next,
                        .swiper-button-prev {
                            color: #fff;
                            z-index: 5;
                            font-family: "icomoon";
                            position: relative;
                            width: 50px;
                            height: 50px;
                            background-color: transparent;
                            .box-shadow(inset 0 0 0 3px @gold)
                        }

                        .swiper-button-next {
                            left: 70px;

                            &:before {
                                content: "\e907";
                                line-height: 50px;
                            }
                        }
                        .swiper-button-prev {
                            left: 0;
                            margin-top: -50px;

                            &:before {
                                content: "\e906";
                                line-height: 50px;
                            }
                        }
                    }
                }
            }

            .blocco-full {
                height: 550px;

                &.qnt {
                    .text {
                        .opacity(1);
                        top: 50%;
                    }
                }

                .text {
                    top: 60%;
                    left: 50%;
                    .transform(translate(-50%, -50%));
                    color: @white;
                    position: relative;
                    .opacity(0);
                    .transition(all ease-in-out .3s);

                    h3 {
                        text-transform: uppercase;
                        letter-spacing: .3em;
                        font-size: .8em;
                        margin: 0;
                    }
                    h2 {
                        font-family: @serif;
                        font-size: 4vw;
                        margin: 0;
                    }

                    a.btn {
                        margin-top: 50px;
                        color: @white;
                    }
                }
            }

            .services {
                padding: 70px;
                .flexbox(row);
                align-items: center;
                justify-content: space-evenly;
                position: relative;
                .transition(all ease-in-out .3s);
                top: 30px;
                .opacity(0);

                &.qnt {
                    top: 0px;
                    .opacity(1);
                }

                >h3 {
                    color: @black;
                    text-align: left;
                    font-family: @serif;
                    font-size: 1.8em;
                    padding: 0;
                    // padding-left: 5%;

                    @media all and (min-width: 1281px) {
                        flex-basis: 30%;
                    }

                    &:first-letter {
                        color: @gold;
                        font-size: 2.8em;
                        vertical-align: text-top;
                        line-height: 1;
                        float: left;
                        margin-top: .05em;
                        margin-right: .05em;
                    }
                }

                ul {
                    list-style-type: none;
                    -webkit-columns: 2;
                    -moz-columns: 2;
                    columns: 2;
                    -webkit-column-gap: 60px;
                    -moz-column-gap: 60px;
                    column-gap: 60px;
                    list-style-position: inside;
                    text-align: left;
                    padding-left: 0;

                    li {
                        line-height: 2.6em;
                        position: relative;
                        padding-left: 25px;

                        &:before {
                            position: absolute;
                            content:"";
                            width: 14px;
                            height: 14px;
                            .border-radius(50%);
                            border: 1px solid @gold;
                            left: 0;
                            top: 50%;
                            .transform(translateY(-50%));
                        }
                    }
                }
            }
        }
    }
}
