.booking {
    position: fixed;
    padding: 0;
    left: 20%;
    width: 60%;
    z-index: 1;
    background-color: fade(@black, 70%)!important;

    .keyframes(BookingOutBottom;{
        0%   { bottom: auto; top: 80px; opacity: 1;}
        20%  { bottom: auto; top: -100px; opacity: 0;}
        30%  { top: auto; bottom: -100px; opacity: 0;}
        100% { top: auto; bottom: 60px;  opacity: 1;}
    });

    .animation(BookingOutBottom 1.2s forwards ease-in-out);

    #sb-container {
        max-width: 1600px;
        margin: 0 auto;

        .sb {
            padding: 10px 20px 20px;
            .flexbox(column);

            /*inverte link footer e form*/
            #sb-container_sb__form {
                order: 1;
            }
            .sb_footer {
                order: -1;
            }
        }

        .sb-custom-bg-color {

        }

        /*imposta margini link footer*/
        a.sb__footer-link.sb__footer-link--edit.sb-custom-link-color.sb-custom-color-hover,
        a#sb-container_sb__footer-promo-wrapper {
            margin-top: 5px;
            margin-bottom: 10px;

            &:hover {
                fill: lighten(@gold, 20%)!important;
                color: lighten(@gold, 20%)!important;
            }
        }

        /*imposta colore icone promo code/modify*/
         .sb__footer-promo-wrapper .sb-custom-icon-color,
         .sb__footer-link .sb-custom-icon-color {
            fill: @gold!important;
            color: @gold!important;
        }

        /*disattiva label*/
        span.sb__form-field-label.sb-custom-label-color {
            display: none;
        }

        /*resetta border radius*/
        .sb-screen-xs .sb__form-field--checkin .sb__form-field-input,
        .sb-screen-xs .sb__form-field--checkout .sb__form-field-input,
        .sb__form-field-input,
        .sb__btn--verify {
            border-radius: 0;
            .box-shadow(inset 0 0 0 2px fade(@gold, 50%))!important;

            &:hover {
                .box-shadow(inset 0 0 0 2px fade(@gold, 80%))!important;
            }
        }

        /*margine bottone prenota*/
        .sb__btn--verify {
            margin-top: 0;
            font-size: 1em;

            &:hover {
                background-color: transparent!important;
                color: @gold!important;
            }
        }

        .sb__calendar {
            .box-shadow(inset 0 0 0 2px fade(@gold, 30%))!important;
            .border-radius(0);
        }
    }

    &.affix,
    .no-background & {
        bottom: 50px;

        .keyframes(BookingInTop;{
            0%   { top: auto; bottom: 50px; opacity: 1;}
            5%  { top: auto; bottom: 20px; opacity: 0;}
            25%  { bottom: auto; top: 0px; opacity: 0;}
            40%  { bottom: auto; top: 80px; width: 100%; left: 0; opacity: 1; padding: 0 5vw;}
            100% { bottom: auto; top: 80px; width: 100%; left: 0; opacity: 1; padding: 0 5vw;}
        });

        .animation(BookingInTop 1.2s forwards ease-in-out .1s);
    }
}
